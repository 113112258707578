import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import SharedMultiCardContent from '../.././../components/organism/SharedMultiCardcontent/SharedMultiCardContent';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators';
import useStyles from './style';
import usePillarStyles from '../PillarsCarousel/style';
import { useSelector, useDispatch } from 'react-redux';
import { getMultiFilterResultStart } from '../../../containers/common/MultiFilteredLayout/redux/actions';
import { useTheme } from '@material-ui/core/styles';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';
import Box from '../.././../components/molecules/Box';
import clsx from 'clsx';

const MultiFilteredLayout = props => {
  const { multiFilterData, filterSelected } = useSelector(state => state.MultiFilterLayoutReducer);
  const classes = useStyles(props);
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const [multiFilterResultMock, setMultiFilterResultMock] = useState()
  const pillarCarouselClasses = usePillarStyles(props);
  const [multifilterCategoryBtns, setMultifilterCategoryBtns] = useState([])

  const dispatch = useDispatch();

  const getRequestData = (offset = 0, limit = 10, filters = []) => {
    return {
      'searchTerm': 'multifilter',
      'language': isRTL ? 'ar' : 'en',
      'facetFields': [
      ],
      'ignoreSearchTerm': true,
      'limit': limit,
      'offset': offset,
      filters,
    }
  }


  useEffect(() => {
    const categoriesItem = props.fields.categories
    const filterBtns = categoriesItem.map(item => {
      const { title, contentType } = item.fields || {}
      const contentTypeData = contentType.map(val => ({
        key: val.fields.searchKey?.value ?? '',
        value: val.fields.taxonomyKey?.value ?? '',
      }));
      let truncateTitle = title.value
      return { id: item.id, title: truncateTitle, filters: contentTypeData }
    })
    let filters = filterBtns.flatMap(item => item.filters.map(filter => ({ key: filter.value, value: filter.key })));
    dispatch(getMultiFilterResultStart(getRequestData(0, 11, filters)));
    setMultifilterCategoryBtns(filterBtns)
  }, [])

  useEffect(() => {
    const filters = [...filterSelected]
    const updateResult = Array.isArray(multiFilterData) && multiFilterData.map(item => {
      const { itemUniqueKey, name, location, description, thumbnailImage, interestType, interestTypeKey, itemUrl } = item
      const findIndex = Array.isArray(interestTypeKey) && interestTypeKey.findIndex(val => filters.includes(val))
      const tagVal = interestType[findIndex] || interestType[0]
      return {
        fields: {
          title: { value: name },
          link: itemUrl,
          location: { value: location },
          tag: [tagVal],
          attributeLabelCTA: { value: {} },
          image: { value: thumbnailImage ?? { src: {} } }
        },
        id: itemUniqueKey
      }
    })

    setMultiFilterResultMock(updateResult)
  }, [multiFilterData])

  const updatedProps = {
    ...props,
    ...{
      fields: {
        ...props.fields,
        ...{
          carouselItems: multiFilterResultMock,
          multifilterCategoryBtns,
          pageName: getPageName(props),
          componentName: getComponentName(props),
        },
      },
    },
  };

  return (
    <>
      <Box className={clsx(pillarCarouselClasses.pillarCarousel, classes.multiFiltered)}>
        {
          multiFilterResultMock && (
            <SharedMultiCardContent
              {...updatedProps}
              data-locator={COMPONENT_NAME}
            />
          )
        }

      </Box>
    </>
  );
};

MultiFilteredLayout.propTypes = {
  carouselItems: PropTypes.array,
  title: PropTypes.object,
  description: PropTypes.object,
};

MultiFilteredLayout.defaultProps = {
  carouselItems: [],
  title: {},
  description: {},
};


export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(MultiFilteredLayout))
);
