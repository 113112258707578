import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
  multiFiltered: {
    position: 'relative',
    '& .doubleImage': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '448px',
      minHeight: 'unset',

      [theme.breakpoints.down('lg')]: {
        height: '416px',
      },

      [theme.breakpoints.down('sm')]: {
        height: '380px',
      },

      '& .multiFilteredCardImageWrapper': {
        minHeight: 'unset',
        height: '208px',

        [theme.breakpoints.down('lg')]: {
          height: '190px',
        },

        [theme.breakpoints.down('sm')]: {
          height: '182px',
        },
      },
    },

    '& .loader-multifilter': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 20,
      opacity: 1,
      transform: 'translate(-50%, -50%)',
    },
    '& .multiFilteredCardImageWrapper': {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      // height: pxToRem(448),
      height: '448px',
      '&:before': {
        content: '""',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'linear-gradient(0deg, #000 0%, rgba(69.06, 69.06, 69.06, 0) 100%)',
        opacity: 0.5,
        transition: 'all .3s ease',
      },

      '&:hover': {
        '&:before': {
          background: 'linear-gradient(0deg, #000 0%, rgba(69.06, 69.06, 69.06, 0) 100%)',
          opacity: 0.8,
        },
      },

      [theme.breakpoints.down('lg')]: {
        height: '416px',
      },

      [theme.breakpoints.down('sm')]: {
        height: pxToRem(380),
      },
      '& .multi-filter-content': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%',
        width: '90%',
        position: 'absolute',
        ...theme.mixins.paddingLeft(16),
        color: theme.palette.common.White,
        zIndex: '10',
        transition: 'all .5s ease',

        [theme.breakpoints.up('lg')]: {
          paddingBottom: '24px',
          ...theme.mixins.paddingLeft(24),
        },
        '& .filter-title > div': {
          display: 'unset',
        },
        '& .filter-title': {
          fontSize: '22px',
          fontWeight: 700,
          lineHeight: '32px',
          '& h2': {
            ...theme.palette.common.ellipsis(2),
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '22px',
            lineHeight: '32px',
            marginBottom: pxToRem(16),
          },
        },

        '& .filter-category-tag': {
          color: theme.palette.common.White,
          border: '1px solid #FFFF',
          maxWidth: 'fit-content',
          marginBottom: pxToRem(8),
          '& span': {
            fontWeight: 700,
          },
        },
      },

    },

    '& .MultiFilteredCard': {
      overflow: 'hidden',
      transition: 'opacity 500ms ease-in',

      '& .multiFilterPillarCard': {
        '& .multiFilterWrapper': {
          position: 'relative',
          '&:before': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(69, 69, 69, 0.00) 100%)',
            zIndex: 1,
          },
          '& .multifilter-tag': {
            background: '#FFFFFF',
            padding: '7px 8px',
            position: 'absolute',
            borderRadius: '4px',
            zIndex: 1,
            [theme.breakpoints.up('lg')]: {
              marginTop: '40px',
              // marginLeft: '40px',
              ...theme.mixins.marginLeft(40)
            },
            [theme.breakpoints.down('lg')]: {
              marginTop: '24px',
              // marginLeft: '24px',
              ...theme.mixins.marginLeft(24)
            },
            '& p': {
              color: '#000',
              margin: 0,
              fontSize: pxToRem(9),
              // fontWeight: 700,
              lineHeight: '10px',
              letterSpacing: '1px',
              textTransform: 'uppercase'
            }
          },

          '& .pillarContent': {
            '&.multifilterContent': {
              width: '100%',
              top: 'unset',
              bottom: 0,
            },
            [theme.breakpoints.up('lg')]: {
              bottom: '0',
              justifyContent: 'unset',
              ...theme.mixins.paddingLR(40, 40),
              // transform: 'translateY(0)',
            },
            [theme.breakpoints.down('md')]: {
              bottom: '0',
              transform: 'translateY(0)',
              ...theme.mixins.paddingLR(24, 24),
              paddingBottom: '24px',
            },
            '& .pillarTitle': {
              marginBottom: 0,
              [theme.breakpoints.up('lg')]: {
                transform: 'translateY(0)',
                transition: 'all .5s ease',
              },
              [theme.breakpoints.down('md')]: {
              },
            }
          },

          '&:hover .pillarContent': {
            [theme.breakpoints.up('lg')]: {
              // transform: 'translateY(-37%)',
              // transform: 'translateY(-100%)',
              transform: 'translateY(0)',
              '& .pillarTitle': {
                transform: 'translateY(0)',
              },
              '& .pillarDescription': {
                opacity: 1,
              },
            },
            [theme.breakpoints.up('xl')]: {
              // transform: 'translateY(-33%)',
              '& .pillarDescription': {
                opacity: 1,
              },
            },
          },

        },
      },
      '& .multiFilterLoader': {
        textAlign: 'center',
        marginBottom: '-514px',
        minHeight: '514px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .pillar-shared-description': {
        [theme.breakpoints.down('lg')]: {
          marginBottom: '32px',
        },

      },
    },

    '& .showSkeleton': {
      '& .multiFilteredCardImageWrapper': {
        background: '#F7F7F7',
        '&:before': {
          background: '#F7F7F7',
        },
        '&:hover': {
          '&:before': {
            background: '#F7F7F7',
          },
        },
      },
    },

    '& .skeletonBox': {
      minHeight: '0',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      marginLeft: '5.5%',
      marginRight: '5.5%',
      opacity: 0,
      height: '0',
      transition: 'opacity 0.5s linear',
      position: 'relative',
      '& .innerText': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,calc(-50% - 10px))',
        [theme.breakpoints.down('md')]: {
          transform: 'translate(-50%,calc(-50% - 25px))',
          width: '60%',
        },
        height: 0,
        '& span': {
          opacity: 0,
        },
      },

      '&.sty2': {
        minHeight: 'auto',
        justifyContent: 'flex-start',
      },
      '&.transition': {
        opacity: '0 !important',
      },
      '&.visible': {
        opacity: 1,
        height: '100%',
        minHeight: '100%',
        '& .innerText': {
          '& span': {
            opacity: 1,
          },
        },
      },
      [theme.breakpoints.down('md')]: {

        minHeight: '0',
        marginLeft: '0',
        // marginRight: '24px',
        ...theme.mixins.marginLeft(24),
        '&.innerText': {
          height: '100%',
        },
      },
      '& .innerBox': {
        width: '398px',
        marginLeft: '32px',
        background: '#F7F7F7',
        minHeight: '448px',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          minHeight: '386px',
          marginLeft: '12px',
          '&:nth-child(2)': {
            display: 'none',
          },
          '&:nth-child(3)': {
            display: 'none',
          },
        },
        '&:first-child': {
          marginLeft: '12px',
        },
        '&.sty2': {
          background: 'transparent',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          [theme.breakpoints.down('md')]: {
            width: '58px',
          },
          '& .boxWrap': {
            background: '#F7F7F7',
            minHeight: '208px',
            [theme.breakpoints.down('md')]: {
              minHeight: '185px',
            },
          },
        },
      },
    },


    '& .MuiBox-root': {
      marginTop: pxToRem(0),
      marginBottom: pxToRem(0),
    },

    '& .multi-filter-header': {
      minHeight: 'unset',
      '& h2': {
        fontWeight: 700,
      },
      '&.multi-filter-height': {
        paddingBottom: pxToRem(0),
        [theme.breakpoints.down('md')]: {
          paddingBottom: pxToRem(16),
        },
      },
    },

    '& .multi-filter-category-btn-container': {
      display: 'flex',
      '& .multi-filter-category-btn': {
        padding: '11px 16px',
        width: '100%',
        textTransform: 'uppercase',
        border: '1px solid #DDDEDE',
        borderRadius: '1.75rem',
        backgroundColor: '#fff',
        fontWeight: '700',
        fontSize: '12px',
        letterSpacing: '0.5px',
        lineHeight: '16px',
        color: '#000',
        transition: 'background-color 0.3s linear',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 1.5rem 2rem',
      },
      '& .multi-filter-category-btn-active': {
        backgroundColor: '#f7f7f7',
        border: '1px solid #f7f7f7',
        margin: '0 1.5rem 1rem',
        '& svg': {
          ...theme.mixins.marginRight(8),
        }
      },
    },


    '& .multi-filter-btns-wrapper': {
      opacity: 0,
      visibility: 'hidden',
      height: '0',
      transition: 'opacity 0.9s linear',
      '&.mulit-filter-btn-active': {
        opacity: 1,
        visibility: 'visible',
        height: '100%',
      },

      '&.multi-filter-btns-height': {
        opacity: 0,
        visibility: 'visible',
        height: '100%',
      },
      [theme.breakpoints.down('lg')]: {
        overflowX: 'scroll',
        // marginBottom: '24px',
        marginBottom: '14px',
        paddingBottom: '10px',
      },
    },
    '& .multi-filter-btns-alignment': {
      ...theme.mixins.marginLR('5.5%', '5.5%'),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',

      [theme.breakpoints.down('md')]: {
        // display: 'inline-flex',
        // flexWrap: 'nowrap',
      },

      '& .btn-txt': {
        lineHeight: 1,
        textAlign: 'center',
        display: 'flex',
        '& span': {
          wordBreak: 'break-all',
          // ...theme.palette.common.ellipsis(1),
        },
      },

      '& .filter-clear-btn': {
        width: '172px',
        [theme.breakpoints.down('lg')]: {
          width: 'max-content',
          ...theme.mixins.paddingRight('34px'),

        },
        '& .filterBar': {
          ...theme.mixins.marginLR('16px', '16px'),
          height: '25px',
          width: '2px',
          border: '1px solid #DDDEDE',
        },
        '& .filter-clear-btn-text': {
          position: 'relative',

          [theme.breakpoints.up('lg')]: {
            ...theme.mixins.paddingLeft('16px'),
            '&:before': {
              display: 'block',
              position: 'absolute',
              top: pxToRem(-2),
              ...theme.mixins.left(pxToRem(0)),
              height: pxToRem(25),
              border: '1px solid #DDDEDE',
              content: '""',
            },
          },

          [theme.breakpoints.down('md')]: {
            '&:after': {
              display: 'block',
              position: 'absolute',
              top: pxToRem(-2),
              ...theme.mixins.right(pxToRem(-17)),
              height: pxToRem(25),
              border: '1px solid #DDDEDE',
              content: '""',
            },
          },

          cursor: 'pointer',
          color: '#FF8707',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },

    '& .multiFilterSlider': {
      opacity: 0,
      visibility: 'hidden',
      height: '0',
      transition: 'opacity 0.9s linear',

      '&.multiFilterActive': {
        opacity: 1,
        visibility: 'visible',
        height: '100%',
        marginTop: pxToRem(0),
        [theme.breakpoints.up('lg')]: {
          marginTop: pxToRem(32),
        },
      },
      '& .slick-track': {
        overflow: 'hidden',
        marginLeft: 'auto',
      },
      '&.multiFilterSliderHeightVisible': {
        height: '100%',
        marginTop: pxToRem(0),
        [theme.breakpoints.up('lg')]: {
          marginTop: pxToRem(32),
        },
      },
    },

    '& .multiFilterPillarCard': {
      opacity: 1,
      visibility: 'visible',
      height: '100%',
      transition: 'opacity 1s ease',
      '&.multiFilterPillarActive': {
        opacity: 1,
        visibility: 'visible',
        height: '100%',
      },
      '&.multiFilterHeightAlingment': {
        opacity: 0,
        visibility: 'hidden',
        height: '100%',
      },
      '&.multiFilterHeightAlingmentAuto': {
        opacity: 0,
        visibility: 'hidden',
        height: '0',
      },
      '&.multiFilterHeightVisible': {
        height: '0',
      },
    },

    '& .hidden-tags-for-calc': {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
    },

    '& .tagswrapper ': {
      '& .MuiChip-root': {
        // marginRight: '4px',
        ...theme.mixins.marginRight('4px'),
      },
    },

    '& .multi-filter-btn': {
      // width: '172px',
      // height: '40px',
      ...theme.mixins.paddingLR(pxToRem(16), pxToRem(16)),
      ...theme.mixins.marginRight('12px'),
      [theme.breakpoints.up('lg')]: {
        // marginBottom: '16px',
        marginBottom: '40px',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '8px',
        ...theme.mixins.marginRight('8px'),
      },
      '& .MuiButton-label': {
        padding: '15px 24px',
        [theme.breakpoints.down('md')]: {
          padding: '9px 16px',
        },

      },
      '&.Mui-disabled': {
        opacity: '0.7',
      },
      '&.MuiButton-contained': {
        padding: 0,
        border: '1px solid #DDDEDE',
        boxShadow: 'none',
        backgroundColor: theme.palette.common.White,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
          border: '1px solid #AAAAAA',
        },
        '& span': {
          color: '#565A5C',
        },
        '&:hover': {
          border: '1px solid #DDDEDE',
          boxShadow: 'none',
          color: theme.palette.common.White,
          '& span': {
            color: theme.mixins.Black(),
          },
        },
      },

      '&.multi-filter-btn-variant': {
        '&.MuiButton-contained': {
          borderColor: '#FF8707',
          backgroundColor: '#FF8707',
          '& span': {
            color: theme.palette.common.White,
          },
          '&:hover': {
            '& span': {
              color: theme.palette.common.White,
            },
          },
        },
      },
    },



    overflow: 'hidden',
    '&.hideMultifilterCarousel': {
      opacity: 0,
      pointerEvents: 'none',
    },
    // '& .pillarImageWrapper': {
    //   [theme.breakpoints.up('lg')]: {
    //     height: pxToRem(512),
    //   },
    // },
    '& .journeyBtn': {
      height: '48px',
      ...theme.mixins.marginLeft('5.5%'),
      transition: 'opacity 0.9s linear',
      marginTop: pxToRem(24),
      fontSize: pxToRem(22),
      [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(16),
      },
      '&.journeyBtnfadeOut': {
        opacity: 0,
      },
      '&.journeyBtnhidden': {
        display: 'none',
      },
    },

    '& .isDisabled': {
      '& .MuiButton-endIcon': {
        backgroundColor: theme.palette.common.LightGrey,
      },
      '& .MuiButton-label': {

        '& .btn-txt': {
          color: theme.palette.common.LightGrey,

        },
      },
    },

    [theme.breakpoints.up('lg')]: {
      '& .MultiFilteredCard ': {
        '&.mainPillarHead': {
          [theme.breakpoints.up('lg')]: {
            '& h2': {
              fontWeight: 700,
            }
          },
        },
        '& .rtlClass': {
          direction: theme.direction,
          height: '100%',
        },

        '& .slick-list': {
          overflow: 'visible',
          ...theme.mixins.marginLeft(0),
        },

        '& .slick-track': {
          paddingBottom: '0',
        },

        '& .slick-disabled': {
          display: 'none !important',
        },

        '& .multiFilterPillarCard': {
          '& .slick-slide': {
            ...theme.mixins.marginLeft(0),
            ...theme.mixins.marginRight(32),
            '&:hover': {
              '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
                transform: 'unset !important',
              },
            },
          },
        },

        '& .multiFilterSlider': {
          // position: 'relative',
          overflow: 'hidden',
          '& .multiFilteredCardImageWrapper': {
            '&:hover': {
              '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
                transform: 'scale3d(1.1, 1.1, 1.1) !important',
              },
            },
          },
          '& .slick-next': {
            left: 'auto',
            right: '5.5%',
            transform: 'translate(calc(37%), -50%)',
          },
          '& .slick-prev': {
            left: '5.5%',
            right: 'auto',
            transform: 'translate(calc(-37%), -50%)',
          },

          '& .slick-slide': {
            overflow: 'hidden',
            '&:hover': {
              '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
                transform: 'unset !important',
              },
            },
          },
        },



        // '& .slick-next': {
        //   left: 'auto',       
        //   right: '28px',
        // },
        // '& .slick-prev': {
        //   left: '28px',
        //   right: 'auto',
        // },
        '& .slick-next': {
          left: 'auto',
          right: '5.5%',
          transform: 'translate(calc(100%), -50%)',
        },
        '& .slick-prev': {
          left: '5.5%',
          right: 'auto',
          transform: 'translate(calc(-100%), -50%)',
        },

        '& .slick-next > button, & .slick-prev  > button': {
          border: 'none',
          width: '56px',
          height: '56px',
          boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.12)',
          '&:hover': {
            backgroundColor: (props) => !isEmpty(props.fields?.arrowColor?.value) ? props.fields?.arrowColor?.value : '#FF8707',
          },
        },
      },

      '& .multiFilterSlider': {

        '& .slick-slide': {
          ...theme.mixins.marginLeft(0),
          ...theme.mixins.marginRight(32),

        },
      },

    },

    [theme.breakpoints.up('xxl')]: {
      '& .MultiFilteredCard ': {
        '& .slick-next': {
          left: 'auto',
          right: '5.5%',
          transform: 'translate(calc(105%), -50%)',
        },
        '& .slick-prev': {
          left: '5.5%',
          right: 'auto',
          transform: 'translate(calc(-105%), -50%)',
        },

        '& .multiFilterSlider ': {
          '& .slick-next': {
            left: 'auto',
            right: '5.5%',
            transform: 'translate(calc(35%), -50%)',
          },
          '& .slick-prev': {
            left: '5.5%',
            right: 'auto',
            transform: 'translate(calc(-35%), -50%)',
          },
        },
      },
    },

    [theme.breakpoints.down('md')]: {
      // marginTop: pxToRem(56),
      marginBottom: pxToRem(56),
      '& .MultiFilteredCard ': {
        '& .multiFilterPillarCard': {
          ...theme.mixins.paddingRight(0),
        },
        '& .slick-slide': {
          ...theme.mixins.marginRight(24),
          '& .multi-filtered-super-img': {
            '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
              transform: 'unset !important',
            },
          },
        },
        '& .slick-track': {
          paddingBottom: '0',
        },
      },
    },
  },
}))

export default useStyles;